<template>
  <div class="container msg-room">
    <div
      class="msg-room-top msg-room-top-messageRoom">
      <div
        class="ui-border-line"
        :style="{
          height: '2px',
          backgroundColor: '#F5F5F5',
        }"
        ></div>
      <!-- <span
        class="ui-badge ui-badge-alert"
        :style="{
          margin: '20px 27px',
        }"
        >주의</span><br>
      <p
        :style="{
          margin: '0 30px 20px',
          fontSize: '1.8rem',
        }"
        >
        <template v-if="userData.category === 'client'">
          안전결제 외 다른 결제로 유도하는 경우 거래에 신중해주세요.<br>대금 보호 및 거래 안정성을 보장할 수 없습니다.
        </template>
        <template v-else>
          안전결제 외 다른 결제로 유도하는 경우 서비스 이용에<br>제재를 받을 수 있습니다.
        </template>
      </p> -->
      <p
        v-if="offerData"
        :style="{
          margin: '18px 30px',
          fontSize: '1.8em',
          color: '#000'
        }"
      >
        <template v-if="offerData">
          {{offerData.eventType}}<br>
          {{moment(Number(offerData.playDate) * 1000).format('YYYY.MM.DD HH:mm')}} {{offerData.address}}
        </template>
      </p>
    </div>

    <div class="msg-room-navi">
      <ul
        v-if="navi.forArtist"
        class="navi-forArtist"
      >
        <li @click="() => $gotoWeb(`/artist/my-offer/${roomData.offerUid}/${roomData.targetUseridx}?ableChangePrice=true`)">
          견적서 보기/수정
        </li>
        <li @click="() => goOutMsgRoom()">
          채팅방 나가기
        </li>
      </ul>
    </div>

    <div class="msg-room-wrap" ref="container" @scroll="e => onScroll(e)">
      <ul class="msg-list">
        <!-- client -->
        <template v-if="isClient">
          <template v-if="isPaidOffer">
            <li class="msg-item">
              <div
                class="profile ui-bg-img"
                :style="{ backgroundImage: `url(${$lib.imgUrl('img/logo-xs.png')})` }"
              />
              <div class="info">
                <div class="name ui-ellipsis">헤이비글</div>
                <div class="msg">
                  <span class="color-lavender">헤이비글 알리미</span><br>
                  <br>
                  <span style="font-weight: 500;">예약이 완료되었어요!</span><br>
                  <span style="color: #5a5a5a;">이제 상세한 내용을<br>협의하세요.</span><br>
                  <br>
                  <span style="color: #5a5a5a;">전문가와 빠른 소통이 필요하다면?<br>📞로 전문가와 직접 연락하세요.</span><br>
                </div>
                <div class="date">{{moment(roomData.createdDate * 1000).format('YY.MM.DD HH:mm')}}</div>
              </div>
            </li>

            <li class="msg-item" v-if="/(결혼식|주례없는 결혼식)/.test(offerData.eventType)">
              <div
                class="profile ui-bg-img"
                :style="{ backgroundImage: `url(${$lib.imgUrl('img/logo-xs.png')})` }"
              />
              <div class="info">
                <div class="name ui-ellipsis">헤이비글</div>
                <div class="msg">
                  <span class="color-lavender">헤이비글 알리미</span><br>
                  <br>
                  <span style="font-weight: 500;">[100%지급]리뷰 이벤트</span><br>
                  <br>
                  <span style="color: #5a5a5a;">원하는 전문가를 섭외하셨다면?<br>리뷰 작성하고 상품권 받아가세요.</span><br>
                  <br>
                  <span
                    class="ui-btn"
                    style="color: #0056FF; text-decoration: underline;text-underline-position: under;"
                    @click="() => {
                      $gotoWeb('https://bit.ly/3M28tPF')
                    }"
                  >자세히 알아보기</span>
                </div>
                <div class="date">{{moment(roomData.createdDate * 1000).format('YY.MM.DD HH:mm')}}</div>
              </div>
            </li>
          </template>
          <!-- new멤버십 -->
          <template v-else>
            <li class="msg-item">
              <div
                class="profile ui-bg-img"
                :style="{ backgroundImage: `url(${$lib.imgUrl('img/logo-xs.png')})` }"
              />
              <div class="info">
                <div class="name ui-ellipsis">헤이비글 알리미</div>
                <div class="msg">
                  <span class="ui-weight-5">알아두세요!</span><br>
                  <br>
                  <span style="color: #5a5a5a;">1. 전화 상담 및 연락처 교환은<br />결제(예약) 후에만 할 수 있어요.</span><br>
                  <br>
                  <span style="color: #5a5a5a;">2. 상담이 끝나면 ‘예약하기’로<br />섭외 예약을 완료하세요.</span><br>
                  <br>
                  <span
                    class="ui-btn"
                    style="color: #0056FF; text-decoration: underline;text-underline-position: under;"
                    @click="() => gotoPayment()"
                  >예약하기</span>
                </div>
                <div class="date">{{moment(roomData.createdDate * 1000).format('YY.MM.DD HH:mm')}}</div>
              </div>
            </li>
          </template>
        </template>
        <!-- artist -->
        <template v-else>
          <template v-if="isPaidOffer">
            <li class="msg-item">
              <div
                class="profile ui-bg-img"
                :style="{ backgroundImage: `url(${$lib.imgUrl('img/logo-xs.png')})` }"
              />
              <div class="info">
                <div class="name ui-ellipsis">헤이비글</div>
                <div class="msg">
                  <span class="color-lavender">헤이비글 알리미</span><br>
                  <br>
                  <span style="font-weight: 500;">예약이 완료되었어요!</span><br>
                  <span style="color: #5a5a5a;">이제 카톡, 통화 등을 통해<br>자유롭게 상담을 진행하세요.</span><br>
                  <br>
                  <span style="color: #5a5a5a;">[참고하세요]<br>취소 및 환불 규정</span><br>
                  <span
                    class="ui-btn"
                    style="color: #0056FF; text-decoration: underline;text-underline-position: under;"
                    @click="() => {
                      $gotoWeb('/etc/refund-policy')
                    }"
                  >자세히 알아보기</span>
                </div>
                <div class="date">{{moment(roomData.createdDate * 1000).format('YY.MM.DD HH:mm')}}</div>
              </div>
            </li>

            <li class="msg-item">
              <div
                class="profile ui-bg-img"
                :style="{ backgroundImage: `url(${$lib.imgUrl('img/logo-xs.png')})` }"
              />
              <div class="info">
                <div class="name ui-ellipsis">헤이비글</div>
                <div class="msg">
                  <span class="color-lavender">헤이비글 알리미</span><br>
                  <br>
                  <span style="font-weight: 500;">행사가 끝나면<br>리뷰를 요청하세요!</span><br>
                  <br>
                  <span style="color: #5a5a5a;">리뷰수가 많은 분들의<br>수익이 절대적으로 많습니다.</span><br>
                </div>
                <div class="date">{{moment(roomData.createdDate * 1000).format('YY.MM.DD HH:mm')}}</div>
              </div>
            </li>
          </template>
          <!-- new멤버십 -->
          <template v-else>
            <li class="msg-item">
              <div
                class="profile ui-bg-img"
                :style="{ backgroundImage: `url(${$lib.imgUrl('img/logo-xs.png')})` }"
              />
              <div class="info">
                <div class="name ui-ellipsis">헤이비글 알리미</div>
                <div class="msg">
                  <span class="ui-weight-5">주의하세요!</span><br>
                  <br>
                  <span style="color: #5a5a5a;">전화 상담 및 연락처 교환은<br />결제(섭외) 후에만 할 수 있어요.<br />위반 시 이용 정지됩니다.</span><br>
                  <br>
                  <span
                    class="ui-btn"
                    style="color: #0056FF; text-decoration: underline;text-underline-position: under;"
                    @click="() => $gotoWeb('/etc/inapposite-policy')"
                  >운영정책 보기</span>
                </div>
                <div class="date">{{moment(roomData.createdDate * 1000).format('YY.MM.DD HH:mm')}}</div>
              </div>
            </li>

            <li class="msg-item">
              <div
                class="profile ui-bg-img"
                :style="{ backgroundImage: `url(${$lib.imgUrl('img/logo-xs.png')})` }"
              />
              <div class="info">
                <div class="name ui-ellipsis">헤이비글 알리미</div>
                <div class="msg">
                  <span class="ui-weight-5">진행 순서 알려드릴게요.</span><br>
                  <br>
                  <span style="color: #5a5a5a;">1. 상담이 끝나면 ‘예약하기’로<br />섭외 확정(결제)을 요청하세요.</span><br>
                  <br>
                  <span style="color: #5a5a5a;">2. 확정 후 전화 또는 카톡 등으로<br />자유롭게 소통하세요.</span><br>
                  <br>
                  <span style="color: #5a5a5a;">3. 행사가 종료되면 리뷰를<br />요청하세요.</span><br>
                </div>
                <div class="date">{{moment(roomData.createdDate * 1000).format('YY.MM.DD HH:mm')}}</div>
              </div>
            </li>
          </template>
        </template>

        <template v-for="(row, key) in msgData">
          <template v-if="isTargetMsg(row)">
            <li class="msg-item" :ref="'msg' + row.msgUid" :key="key">
              <div
                class="profile ui-bg-img"
                :style="{ backgroundImage: `url(${$lib.cdnUrl(isClient ? roomData.targetImgUrl : roomData.userImgUrl)})` }"
                @click="() => {
                  isClient
                    ? $router.push(`/client/my-offer/${roomData.offerUid}/${roomData.targetUseridx}?hideFooter=true`)
                    : $router.push(`/artist/direct-offer/${roomData.offerUid}`)
                }"
              >
              </div>
              <div class="info">
                <div class="name ui-ellipsis">{{row.chatName}}</div>
                <div class="msg"><url-in-text :text="row.msg" /></div>
                <div class="date">{{moment(row.createdDate * 1000).format('YY.MM.DD HH:mm')}}</div>
              </div>
            </li>
          </template>
          <template v-else>
            <li class="msg-item my-msg" :ref="'msg' + row.msgUid" :key="key">
              <div class="info">
                <div class="msg"><div class="badge-no-read" v-if="isNoRead(row)">1</div> <url-in-text :text="row.msg" /></div>
                <div class="date">{{moment(row.createdDate * 1000).format('YY.MM.DD HH:mm')}}</div>
              </div>
            </li>
          </template>
        </template>
      </ul>
    </div>

    <div class="msg-room-input">
      <textarea
        ref="textarea"
        v-model.trim="text"
        @keyup="e => textareaWatch(e.target.value)"
        @focus="scrollDown()"
        required
      />
      <button ref="submitbtn" type="button" @click="() => postMsg()" :disabled="!textClone">전송</button>
    </div>

    <modal
      ref="ReportModal"
      title="신고하기"
      confirm="신고하기"
      :confirmCb="modal => {
        formData.title = modalData.title
        formData.contents = modalData.contents
        postReport()
        modal.hide()
      }"
      :showCb="() => {
        modalData.title = formData.title
        modalData.contents = formData.contents
      }"
    >
      <ul slot="body">
        <li
          class="text-left"
          v-for="(row, key) in reportText" :key="key"
          @click="() =>{
            modalData.title = row
            if (modalData.title !== '기타') modalData.contents = ''
          }"
          :class="{ on: modalData.title === row }"
        >
          {{row}}
          <icon class="icon" :src="`img/common/check${modalData.title === row ? '-on' : ''}.png`" width="2rem" height="8rem" :cover="false" />
          <div class="ui-border-line"></div>
        </li>
        <textarea
          v-if="modalData.title === '기타'"
          class="report-etcTitle" placeholder="사유를 적어주세요 (30자)" maxlength="30" v-model="modalData.contents" :disabled="modalData.title !== '기타'"></textarea>
      </ul>
    </modal>
  </div>
</template>

<script>
import BlankList from '@/components/blank/BlankList'
import Modal from '@/components/common/Modal'
import Icon from '@/components/common/Icon'
import UrlInText from '@/components/common/UrlInText'

export default {
  components: {
    BlankList,
    Modal,
    Icon,
    UrlInText,
  },
  data() {
    return {
      roomData: {},
      msgData: [],
      text: '',
      textClone: '',
      repeatTimer: null,
      formData: {
        title: null,
        contents: '',
      },
      modalData: {
        title: null,
        contents: '',
      },
      reportText: [
        '안전 결제 거부',
        '음란/선정성',
        '허위사실 등록',
        '계약/협의 내용 불이행',
        '기타',
      ],
      textareaTimer: null,
      que: {
        msgData: false,
      },
      targetViewMsgUid: 0,
      offerData: {},
      navi: {
        forArtist: false,
      },
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.userData
    },
    isClient() {
      return this.userData.category === 'client'
    },
    isArtist() {
      return this.userData.category === 'artist'
    },
    artistData() {
      return this.$store.state.user.artistData
    },
    firstMsgUid() {
      return this.msgData.length ? this.msgData[0].msgUid : 0
    },
    lastMsgUid() {
      return this.msgData.length ? this.msgData[this.msgData.length - 1].msgUid : 0
    },
    targetLastMsgUid() {
      const targetMsgs = this.msgData.filter(row => Number(row.sendUseridx) !== Number(this.userData.useridx))
      if (!targetMsgs.length) return 0
      return targetMsgs[targetMsgs.length - 1].msgUid
    },
    isTargetMsg() {
      return row => {
        return Number(row.targetUseridx) === Number(this.userData.useridx)
      }
    },
    isNoRead() {
      return row => {
        if (Number(this.targetLastMsgUid) > Number(row.msgUid)) return false
        return Number(row.msgUid) > Number(this.targetViewMsgUid)
      }
    },
    offerUid() {
      return this.roomData?.offerUid
    },
    isPaidOffer() {
      const condition = {
        default: [
          // () => ['ing', 'selfEnd', 'autoEnd', 'cancel'].indexOf(this.offerData?.nowState) === -1,
          () => this.offerData?.payStatus === '00',
        ],
        artist: [
          () => this.userData?.artistidx == this.offerData?.artistidx,
        ],
        client: [
          () => this.userData?.useridx == this.offerData?.useridx,
          // () => this.artistidx === this.offerData?.artistidx,
        ],
      }
      if (condition.default.every(fn => fn())) {
        return condition[this.isArtist ? 'artist' : 'client'].every(fn => fn())
      }

      return false
    },
  },
  mounted() {
    document.body.style.backgroundColor = '#f5f5f5'

    const allowBack = !!localStorage.getItem('successfulPayment') || this.$route.query.allowBack === 'true'

    if (!allowBack) {
      this.$store.commit('setGnbLeftBtnCb', () => {
        // this.$router.replace('/message/list')
        this.$router.replace('/communicate')
      })
    }

    this.$store.commit('setGnbRightBtnCb', (type) => {
      switch (type) {
        case 'out':
          this.goOutMsgRoom()
          break

        case 'report':
          this.$refs.ReportModal.show()
          break

        case 'phone':
          if (this.isClient) {
            this.callToArtist()
          } else {
            this.callToUser()
          }
          break

        case 'forArtist':
          this.navi.forArtist = !this.navi.forArtist
          break
      }
    })

    this.getRoomData(true).then(err => {
      if (err) {
        this.$router.replace('/message/list')
        return
      }

      this.$store.commit('setGnbBtns', {
        phone: this.isPaidOffer,
        moreBtnInMessageRoomForArtist: this.isArtist && !this.isPaidOffer,
      })

      this.getMsg().then(msgData => {
        this.msgData = this.msgData.concat(msgData.reverse())
        this.scrollDown(this.lastMsgUid)
        this.setRead()
        this.repeatGetMsg()

        const isReadArtistInformationAlert = localStorage.getItem('isReadArtistInformationAlert')

        // 전문가 안내 문구
        if (!isReadArtistInformationAlert && !this.isClient) {
          localStorage.setItem('isReadArtistInformationAlert', '1')
          this.$nextTick().then(() => {
            this.$store.commit('setModal', {
              show: true,
              body: this.$lib.imgUrl('img/message/information-artist.png', true),
              bodyType: 'image',
              confirm: '확인',
              maxWidth: '44rem',
              bodyMaxHeight: '80vh',
              useCancel: false,
            })
          })
        }
      })
    })
  },
  beforeDestroy() {
    clearTimeout(this.repeatTimer)
    document.body.style.backgroundColor = null
  },
  beforeRouteEnter(to, from, next) {
    document.body.style.backgroundColor = '#f5f5f5'
    next()
  },
  beforeRouteLeave(to, from, next) {
    document.body.style.backgroundColor = null
    this.$store.commit('setGnbTitle', null)
    this.$store.commit('setGnbLeftBtnCb', false)
    this.$store.commit('setGnbRightBtnCb', false)
    this.$store.commit('setGnbBtns', {
      phone: false,
      moreBtnInMessageRoomForArtist: false,
    })
    clearTimeout(this.repeatTimer)
    next()
  },
  methods: {
    textareaWatch(val) {
      clearTimeout(this.textareaTimer)
      this.textareaTimer = setTimeout(() => {
        this.textClone = val
      }, 50)
    },

    getRoomData(init) {
      const req = {
        method: 'post',
        url: `/chat/${this.$route.params.offerUid}`,
        data: {
          targetUseridx: this.$route.params.targetUseridx,
          targetTeamName: null,
        }
      }

      if (!init) req.loading = false

      return this.$http(req)
        .then(async ({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.roomData = data.response.roomData

            // 제목
            this.$store.commit('setGnbTitle', this.isClient ? this.roomData.targetTeamName : this.roomData.userName)

            if (this.roomData?.offerUid) {
              await this.getOffer()
            }

            if (!this.validateAccess()) {
              this.$router.back()
              setTimeout(() => {
                this.$store.commit('setModal', {
                  show: true,
                  body: '오류가 발생했습니다.<br>어플 종료 후 다시 시도해주세요.',
                  useCancel: false,
                })
              }, 200)
              return
            }

            // if (this.isClient) {
            //   this.setRead(this.roomData.userViewMsgUid)
            // } else {
            //   this.setRead(this.roomData.userViewMsgUid)
            // }

            return false
          } else {
            this.$alert(resultData)
            throw resultData
          }
        })
        .catch((e) => {
          log.error(e)
          return true
        })
    },
    postReport() {
      if (!this.formData.title) return

      const reportUseridx = this.isClient ? this.roomData.targetUseridx : this.roomData.useridx
      const req = {
        method: 'post',
        url: `/user/report/${reportUseridx}`,
        data: {
          title: this.formData.title,
          contents: this.formData.contents,
        }
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.$toast('신고가 처리되었습니다.')
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    getMsg(msgUid = 0, limit = 0) {
      if (this.que.msgData) return new Promise(r => r([]))
      this.que.msgData = true

      const req = {
        method: 'get',
        url: `/chat/msg/${this.roomData.roomUid}/${msgUid || 0}/${limit || 0}`,
        loading: false,
      }

      return this.$http(req)
        .then(({ data }) => {
          this.que.msgData = false
          // log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            let msgData = data?.response?.msgData || []
            if (msgData.length > 0 && limit === 0) {
              this.targetViewMsgUid = msgData[0].targetViewMsgUid

              let lastMsgIndex = msgData.findIndex(row => row.msgUid === this.lastMsgUid)
              if (lastMsgIndex !== -1) {
                msgData.splice(lastMsgIndex, 1)
              }
            }
            return msgData
          } else {
            this.$alert(resultData)
            return []
          }
        })
        .catch(error => {
          this.que.msgData = false
          log.error(error)
          return []
        })
    },
    postMsg() {
      if (!this.text) return

      // textarea로 강제 focus
      this.$refs.textarea.focus()

      clearTimeout(this.repeatTimer)

      let data = new FormData()
      data.append('chatName', this.isClient ? this.userData.nickName : this.artistData.teamName)
      // data.append('chatName', this.roomData.userName)
      data.append('msg', this.text)
      data.append('uploadFile', ''), //업로드할 파일
      data.append('targetUseridx', this.isClient ? this.roomData.targetUseridx : this.roomData.useridx)

      const req = {
        method: 'post',
        url: `/chat/msg/${this.roomData.roomUid}/${this.lastMsgUid}`,
        data
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (this.isPaidOffer === false && this.$lib.validNgWordCount(data, this.$store) === false) {
            this.repeatGetMsg()
            return
          }

          if (resultData.success) {
            let msgData = data.response.msgData || []
            let lastMsgIndex = msgData.findIndex(row => row.msgUid === this.lastMsgUid)

            if (lastMsgIndex !== -1) {
              msgData.splice(lastMsgIndex, 1)
            }

            this.$refs.textarea.value = ''
            this.text = ''
            this.textClone = ''
            this.msgData = this.msgData.concat(msgData.reverse())
            this.setRead()
            this.scrollDown(this.lastMsgUid)
            this.repeatGetMsg()
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    repeatGetMsg() {
      this.repeatTimer = setTimeout(() => {
        // 현재 경로가 해당 대화방이 아니면 타이머 종료
        const reg = new RegExp(`^/message/${this.$route.params.offerUid}/${this.$route.params.targetUseridx}`)
        if (!reg.test(this.$route.path)) {
          clearTimeout(this.repeatTimer)
          return
        }

        this.getMsg(this.lastMsgUid).then(msgData => {
          if (msgData && Array.isArray(msgData) && msgData.length > 0) {
            let lastMsgUid = msgData[msgData.length - 1].msgUid

            if (this.lastMsgUid != lastMsgUid) {
              this.msgData = this.msgData.concat(msgData.reverse())
              this.scrollDown(this.lastMsgUid)
            }
          }

          this.repeatGetMsg()
        }).catch(() => {
          clearTimeout(this.repeatTimer)
        })
      }, 3000)
    },
    scrollDown(lastMsgUid = '') {
      this.$nextTick().then(() => {
        try {
          if (lastMsgUid) {
            let lastMsg = this.$refs['msg' + lastMsgUid]
            if (lastMsg) {
              if (Array.isArray(lastMsg) && lastMsg.length > 0) lastMsg = lastMsg[0]
              this.$refs.container.scrollTop = lastMsg.offsetTop
              return
            }
          }

          this.$refs.container.scrollTop = this.$refs.container.scrollHeight
        } catch (error) {
          console.error(error)
        }
      })
    },
    setRead() {
      let lastMsgUid = Number(this.lastMsgUid)
      let viewMsgUid = Number(this.$store.state.user.newMsgData.viewMsgUid)
      if (viewMsgUid < lastMsgUid) {
        this.$store.commit('setMsgUid', {
          mode: 'view',
          msgUid: lastMsgUid,
        })
      }
    },
    onScroll(e) {
      if (e.target.scrollTop <= 0) {
        this.getMsg(this.firstMsgUid, 30).then(msgData => {
          let firstMsgIdx = msgData.findIndex(row => row.msgUid == this.firstMsgUid)
          msgData.splice(firstMsgIdx, 1)
          msgData.sort((a, b) => a.msgUid < b.msgUid ? -1 : 1)
          this.msgData = msgData.concat(this.msgData)
        })
      }
    },
    callToUser() {
      gtag('event', 'pro2user_call_b')

      if (this.roomData.clientPhone) {
        location.href = 'tel:' + this.roomData.clientPhone
      } else {
        this.$toast('전화번호가 등록되지 않은 회원입니다.')
      }
    },
    callToArtist() {
      gtag('event', 'user2pro_call_b')
      this.callCheck(this.roomData.targetUseridx)

      if (this.roomData.artistPhone) {
        location.href = 'tel:' + this.roomData.artistPhone
      } else {
        this.$toast('전화번호가 등록되지 않은 전문가입니다.')
      }
    },
    callCheck(artistidx) {
      const req = {
        method: 'get',
        url: `/artist/telCount/${artistidx}`,
      }
      this.$http(req)
    },
    goOutMsgRoom() {
      this.$store.commit('setModal', {
        show: true,
        body: `
          <span style="font-size: 24px;font-weight: 600;color: #000;">채팅방 나가기</span>

          채팅방을 나가시겠어요?
          나가기를 하면 목록에
          보이지 않아요.`,
        textAlign: 'text-center',
        confirm: '나가기',
        confirmCb: modal => {
          modal.hide()
          const req = {
            method: 'put',
            url: `/chat/${this.roomData.roomUid}/out`,
          }
          this.$http(req).then(() => {
            this.$router.back()
          })
        },
        noBorder: true,
        cancel: '취소',
        cancelCb: (modal) => {
          modal.hide()
        },
      })
    },
    getOffer() {
      if (!this.offerUid) return

      const req = {
        method: 'get',
        url: `/client/offer/${this.offerUid}`,
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.offerData = data.response.offerData || {}
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    gotoPayment() {
      if (this.offerUid) {
        this.$router.push(`/payment/${this.offerUid}/${this.roomData.targetUseridx}`)
      } else {
        this.$router.push(`/payment/custom/${this.roomData.targetUseridx}`)
      }
    },
    validateAccess() {
      if (this.isClient) {
        return this.offerData.useridx === this.userData.useridx
      }

      if (this.offerData.mode === 'direct') {
        return this.offerData.artistidx === this.userData.artistidx
      }

      return true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';
.container.msg-room {
  padding-bottom: 0 !important;
  min-height: auto !important;
  background-color:#e8e8e8 !important;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden !important;
  -webkit-overflow-scrolling: auto !important;

  .msg-room-top {
    background-color: #fff;
    .ui-badge.ui-badge-alert {
      display: inline-block;
      width: 6rem;
      line-height: 3.3rem;
      height: 3rem;
      border-radius: 0.7rem;
      background-color: #f33d12;
      color: #fff;
      font-size: 1.6rem;
      text-align: center;
    }
  }

  .msg-room-navi {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    ul {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 1px solid #f5f5f5;
      border-top: none;
      overflow: hidden;

      li {
        min-width: 185px;
        background-color: #fff;
        border-bottom: 1px solid #f5f5f5;
        font-weight: 500;
        font-size: 17px;
        padding: 30px 40px;
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .msg-room-wrap {
    flex: 1;
    padding: 2rem 3rem;
    overflow-x: hidden;
    overflow-y: auto;
    // ios 스크롤 관련
    -webkit-overflow-scrolling: touch;

    .msg-list {
      height: 100%;
    }

    .msg-item {
      overflow: hidden;
      margin: 2rem 0;
      .profile {
        margin-top: 0.6rem;
        margin-right: 1.6rem;
        float: left;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        background-color: #efefef;
      }
      .info {
        float: left;
        .name {
          font-size: 1.6rem;
          line-height: 2rem;
          color: #5a5a5a;
          max-width: 40rem;
        }
        .msg {
          position: relative;
          margin-top: 0.7rem;
          color: #000;
          background-color: #fff;
          font-size: 1.8rem;
          line-height: 2.2rem;
          min-height: 4.2rem;
          max-width: 40rem;
          padding: 1rem 1.8rem;
          border: 1px solid #ddd;
          border-radius: 0.5rem;
          word-break: break-all;
          .badge-no-read {
            position: absolute;
            display: inline-block;
            left: -2rem;
            color: #a059b6;
            font-size: 1.4rem;
            text-align: center;
            font-weight: 500;
          }
        }
        .date {
          margin-left: 0.5rem;
          color: #a2a2a2;
          font-size: 1.3rem;
          line-height: 2;
        }
      }
      &:not(.my-msg) {
        .info {
          .msg {
            @include tri-left(8, 4, -16);
          }
        }
      }
      &.my-msg {
        .info {
          float: right;
          .msg {
            @include tri-right(8, 4, -16);
            background-color: #ffe81a;
            margin-right: 16px;
          }
        }
      }
    }
  }

  .msg-room-input {
    display: block;
    margin: auto;
    margin-top: auto;
    overflow: hidden;
    width: 100%;
    max-width: 768px;
    textarea {
      float: left;
      border: 0;
      margin: 0;
      padding: 1rem 3rem;
      width: 46.5rem;
      width: calc(100% - 7.5rem);
      line-height: 3rem;
      height: 7.5rem;
      font-size: 2rem;
      color: #5a5a5a;
      resize: none
    }
    button {
      float: right;
      margin: 0;
      padding: 0;
      border: 0;
      width: 7.5rem;
      height: 7.5rem;
      color: #fff;
      font-size: 2rem;
      background-color: $color-deepLavender;
      &:disabled {
        background-color: #9E9E9E;
      }
    }
  }
}

.report-etcTitle {
    width: 100%;
    height: 80px;
    color: #000;
    border: 0;
    margin: 0;
    padding: 2rem 1rem;
    font-size: 1.8rem;
    resize: none;
    textarea {
      padding: 0;
      height: 124px;
      font-size: 18px !important;
      line-height: 1.5;
    }
}
</style>
