var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container msg-room" },
    [
      _c("div", { staticClass: "msg-room-top msg-room-top-messageRoom" }, [
        _c("div", {
          staticClass: "ui-border-line",
          style: {
            height: "2px",
            backgroundColor: "#F5F5F5"
          }
        }),
        _vm.offerData
          ? _c(
              "p",
              {
                style: {
                  margin: "18px 30px",
                  fontSize: "1.8em",
                  color: "#000"
                }
              },
              [
                _vm.offerData
                  ? [
                      _vm._v(" " + _vm._s(_vm.offerData.eventType)),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm
                              .moment(Number(_vm.offerData.playDate) * 1000)
                              .format("YYYY.MM.DD HH:mm")
                          ) +
                          " " +
                          _vm._s(_vm.offerData.address) +
                          " "
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "msg-room-navi" }, [
        _vm.navi.forArtist
          ? _c("ul", { staticClass: "navi-forArtist" }, [
              _c(
                "li",
                {
                  on: {
                    click: function() {
                      return _vm.$gotoWeb(
                        "/artist/my-offer/" +
                          _vm.roomData.offerUid +
                          "/" +
                          _vm.roomData.targetUseridx +
                          "?ableChangePrice=true"
                      )
                    }
                  }
                },
                [_vm._v(" 견적서 보기/수정 ")]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function() {
                      return _vm.goOutMsgRoom()
                    }
                  }
                },
                [_vm._v(" 채팅방 나가기 ")]
              )
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        {
          ref: "container",
          staticClass: "msg-room-wrap",
          on: {
            scroll: function(e) {
              return _vm.onScroll(e)
            }
          }
        },
        [
          _c(
            "ul",
            { staticClass: "msg-list" },
            [
              _vm.isClient
                ? [
                    _vm.isPaidOffer
                      ? [
                          _c("li", { staticClass: "msg-item" }, [
                            _c("div", {
                              staticClass: "profile ui-bg-img",
                              style: {
                                backgroundImage:
                                  "url(" +
                                  _vm.$lib.imgUrl("img/logo-xs.png") +
                                  ")"
                              }
                            }),
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "name ui-ellipsis" }, [
                                _vm._v("헤이비글")
                              ]),
                              _vm._m(0),
                              _c("div", { staticClass: "date" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .moment(_vm.roomData.createdDate * 1000)
                                      .format("YY.MM.DD HH:mm")
                                  )
                                )
                              ])
                            ])
                          ]),
                          /(결혼식|주례없는 결혼식)/.test(
                            _vm.offerData.eventType
                          )
                            ? _c("li", { staticClass: "msg-item" }, [
                                _c("div", {
                                  staticClass: "profile ui-bg-img",
                                  style: {
                                    backgroundImage:
                                      "url(" +
                                      _vm.$lib.imgUrl("img/logo-xs.png") +
                                      ")"
                                  }
                                }),
                                _c("div", { staticClass: "info" }, [
                                  _c(
                                    "div",
                                    { staticClass: "name ui-ellipsis" },
                                    [_vm._v("헤이비글")]
                                  ),
                                  _c("div", { staticClass: "msg" }, [
                                    _c(
                                      "span",
                                      { staticClass: "color-lavender" },
                                      [_vm._v("헤이비글 알리미")]
                                    ),
                                    _c("br"),
                                    _c("br"),
                                    _c(
                                      "span",
                                      { staticStyle: { "font-weight": "500" } },
                                      [_vm._v("[100%지급]리뷰 이벤트")]
                                    ),
                                    _c("br"),
                                    _c("br"),
                                    _vm._m(1),
                                    _c("br"),
                                    _c("br"),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "ui-btn",
                                        staticStyle: {
                                          color: "#0056FF",
                                          "text-decoration": "underline",
                                          "text-underline-position": "under"
                                        },
                                        on: {
                                          click: function() {
                                            _vm.$gotoWeb(
                                              "https://bit.ly/3M28tPF"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("자세히 알아보기")]
                                    )
                                  ]),
                                  _c("div", { staticClass: "date" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm
                                          .moment(
                                            _vm.roomData.createdDate * 1000
                                          )
                                          .format("YY.MM.DD HH:mm")
                                      )
                                    )
                                  ])
                                ])
                              ])
                            : _vm._e()
                        ]
                      : [
                          _c("li", { staticClass: "msg-item" }, [
                            _c("div", {
                              staticClass: "profile ui-bg-img",
                              style: {
                                backgroundImage:
                                  "url(" +
                                  _vm.$lib.imgUrl("img/logo-xs.png") +
                                  ")"
                              }
                            }),
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "name ui-ellipsis" }, [
                                _vm._v("헤이비글 알리미")
                              ]),
                              _c("div", { staticClass: "msg" }, [
                                _c("span", { staticClass: "ui-weight-5" }, [
                                  _vm._v("알아두세요!")
                                ]),
                                _c("br"),
                                _c("br"),
                                _vm._m(2),
                                _c("br"),
                                _c("br"),
                                _vm._m(3),
                                _c("br"),
                                _c("br"),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ui-btn",
                                    staticStyle: {
                                      color: "#0056FF",
                                      "text-decoration": "underline",
                                      "text-underline-position": "under"
                                    },
                                    on: {
                                      click: function() {
                                        return _vm.gotoPayment()
                                      }
                                    }
                                  },
                                  [_vm._v("예약하기")]
                                )
                              ]),
                              _c("div", { staticClass: "date" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .moment(_vm.roomData.createdDate * 1000)
                                      .format("YY.MM.DD HH:mm")
                                  )
                                )
                              ])
                            ])
                          ])
                        ]
                  ]
                : [
                    _vm.isPaidOffer
                      ? [
                          _c("li", { staticClass: "msg-item" }, [
                            _c("div", {
                              staticClass: "profile ui-bg-img",
                              style: {
                                backgroundImage:
                                  "url(" +
                                  _vm.$lib.imgUrl("img/logo-xs.png") +
                                  ")"
                              }
                            }),
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "name ui-ellipsis" }, [
                                _vm._v("헤이비글")
                              ]),
                              _c("div", { staticClass: "msg" }, [
                                _c("span", { staticClass: "color-lavender" }, [
                                  _vm._v("헤이비글 알리미")
                                ]),
                                _c("br"),
                                _c("br"),
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "500" } },
                                  [_vm._v("예약이 완료되었어요!")]
                                ),
                                _c("br"),
                                _vm._m(4),
                                _c("br"),
                                _c("br"),
                                _vm._m(5),
                                _c("br"),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ui-btn",
                                    staticStyle: {
                                      color: "#0056FF",
                                      "text-decoration": "underline",
                                      "text-underline-position": "under"
                                    },
                                    on: {
                                      click: function() {
                                        _vm.$gotoWeb("/etc/refund-policy")
                                      }
                                    }
                                  },
                                  [_vm._v("자세히 알아보기")]
                                )
                              ]),
                              _c("div", { staticClass: "date" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .moment(_vm.roomData.createdDate * 1000)
                                      .format("YY.MM.DD HH:mm")
                                  )
                                )
                              ])
                            ])
                          ]),
                          _c("li", { staticClass: "msg-item" }, [
                            _c("div", {
                              staticClass: "profile ui-bg-img",
                              style: {
                                backgroundImage:
                                  "url(" +
                                  _vm.$lib.imgUrl("img/logo-xs.png") +
                                  ")"
                              }
                            }),
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "name ui-ellipsis" }, [
                                _vm._v("헤이비글")
                              ]),
                              _vm._m(6),
                              _c("div", { staticClass: "date" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .moment(_vm.roomData.createdDate * 1000)
                                      .format("YY.MM.DD HH:mm")
                                  )
                                )
                              ])
                            ])
                          ])
                        ]
                      : [
                          _c("li", { staticClass: "msg-item" }, [
                            _c("div", {
                              staticClass: "profile ui-bg-img",
                              style: {
                                backgroundImage:
                                  "url(" +
                                  _vm.$lib.imgUrl("img/logo-xs.png") +
                                  ")"
                              }
                            }),
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "name ui-ellipsis" }, [
                                _vm._v("헤이비글 알리미")
                              ]),
                              _c("div", { staticClass: "msg" }, [
                                _c("span", { staticClass: "ui-weight-5" }, [
                                  _vm._v("주의하세요!")
                                ]),
                                _c("br"),
                                _c("br"),
                                _vm._m(7),
                                _c("br"),
                                _c("br"),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ui-btn",
                                    staticStyle: {
                                      color: "#0056FF",
                                      "text-decoration": "underline",
                                      "text-underline-position": "under"
                                    },
                                    on: {
                                      click: function() {
                                        return _vm.$gotoWeb(
                                          "/etc/inapposite-policy"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("운영정책 보기")]
                                )
                              ]),
                              _c("div", { staticClass: "date" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .moment(_vm.roomData.createdDate * 1000)
                                      .format("YY.MM.DD HH:mm")
                                  )
                                )
                              ])
                            ])
                          ]),
                          _c("li", { staticClass: "msg-item" }, [
                            _c("div", {
                              staticClass: "profile ui-bg-img",
                              style: {
                                backgroundImage:
                                  "url(" +
                                  _vm.$lib.imgUrl("img/logo-xs.png") +
                                  ")"
                              }
                            }),
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "name ui-ellipsis" }, [
                                _vm._v("헤이비글 알리미")
                              ]),
                              _vm._m(8),
                              _c("div", { staticClass: "date" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .moment(_vm.roomData.createdDate * 1000)
                                      .format("YY.MM.DD HH:mm")
                                  )
                                )
                              ])
                            ])
                          ])
                        ]
                  ],
              _vm._l(_vm.msgData, function(row, key) {
                return [
                  _vm.isTargetMsg(row)
                    ? [
                        _c(
                          "li",
                          {
                            key: key,
                            ref: "msg" + row.msgUid,
                            refInFor: true,
                            staticClass: "msg-item"
                          },
                          [
                            _c("div", {
                              staticClass: "profile ui-bg-img",
                              style: {
                                backgroundImage:
                                  "url(" +
                                  _vm.$lib.cdnUrl(
                                    _vm.isClient
                                      ? _vm.roomData.targetImgUrl
                                      : _vm.roomData.userImgUrl
                                  ) +
                                  ")"
                              },
                              on: {
                                click: function() {
                                  _vm.isClient
                                    ? _vm.$router.push(
                                        "/client/my-offer/" +
                                          _vm.roomData.offerUid +
                                          "/" +
                                          _vm.roomData.targetUseridx +
                                          "?hideFooter=true"
                                      )
                                    : _vm.$router.push(
                                        "/artist/direct-offer/" +
                                          _vm.roomData.offerUid
                                      )
                                }
                              }
                            }),
                            _c("div", { staticClass: "info" }, [
                              _c("div", { staticClass: "name ui-ellipsis" }, [
                                _vm._v(_vm._s(row.chatName))
                              ]),
                              _c(
                                "div",
                                { staticClass: "msg" },
                                [
                                  _c("url-in-text", {
                                    attrs: { text: row.msg }
                                  })
                                ],
                                1
                              ),
                              _c("div", { staticClass: "date" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .moment(row.createdDate * 1000)
                                      .format("YY.MM.DD HH:mm")
                                  )
                                )
                              ])
                            ])
                          ]
                        )
                      ]
                    : [
                        _c(
                          "li",
                          {
                            key: key,
                            ref: "msg" + row.msgUid,
                            refInFor: true,
                            staticClass: "msg-item my-msg"
                          },
                          [
                            _c("div", { staticClass: "info" }, [
                              _c(
                                "div",
                                { staticClass: "msg" },
                                [
                                  _vm.isNoRead(row)
                                    ? _c(
                                        "div",
                                        { staticClass: "badge-no-read" },
                                        [_vm._v("1")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("url-in-text", {
                                    attrs: { text: row.msg }
                                  })
                                ],
                                1
                              ),
                              _c("div", { staticClass: "date" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .moment(row.createdDate * 1000)
                                      .format("YY.MM.DD HH:mm")
                                  )
                                )
                              ])
                            ])
                          ]
                        )
                      ]
                ]
              })
            ],
            2
          )
        ]
      ),
      _c("div", { staticClass: "msg-room-input" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.text,
              expression: "text",
              modifiers: { trim: true }
            }
          ],
          ref: "textarea",
          attrs: { required: "" },
          domProps: { value: _vm.text },
          on: {
            keyup: function(e) {
              return _vm.textareaWatch(e.target.value)
            },
            focus: function($event) {
              return _vm.scrollDown()
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.text = $event.target.value.trim()
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            ref: "submitbtn",
            attrs: { type: "button", disabled: !_vm.textClone },
            on: {
              click: function() {
                return _vm.postMsg()
              }
            }
          },
          [_vm._v("전송")]
        )
      ]),
      _c(
        "modal",
        {
          ref: "ReportModal",
          attrs: {
            title: "신고하기",
            confirm: "신고하기",
            confirmCb: function(modal) {
              _vm.formData.title = _vm.modalData.title
              _vm.formData.contents = _vm.modalData.contents
              _vm.postReport()
              modal.hide()
            },
            showCb: function() {
              _vm.modalData.title = _vm.formData.title
              _vm.modalData.contents = _vm.formData.contents
            }
          }
        },
        [
          _c(
            "ul",
            { attrs: { slot: "body" }, slot: "body" },
            [
              _vm._l(_vm.reportText, function(row, key) {
                return _c(
                  "li",
                  {
                    key: key,
                    staticClass: "text-left",
                    class: { on: _vm.modalData.title === row },
                    on: {
                      click: function() {
                        _vm.modalData.title = row
                        if (_vm.modalData.title !== "기타") {
                          _vm.modalData.contents = ""
                        }
                      }
                    }
                  },
                  [
                    _vm._v(" " + _vm._s(row) + " "),
                    _c("icon", {
                      staticClass: "icon",
                      attrs: {
                        src:
                          "img/common/check" +
                          (_vm.modalData.title === row ? "-on" : "") +
                          ".png",
                        width: "2rem",
                        height: "8rem",
                        cover: false
                      }
                    }),
                    _c("div", { staticClass: "ui-border-line" })
                  ],
                  1
                )
              }),
              _vm.modalData.title === "기타"
                ? _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modalData.contents,
                        expression: "modalData.contents"
                      }
                    ],
                    staticClass: "report-etcTitle",
                    attrs: {
                      placeholder: "사유를 적어주세요 (30자)",
                      maxlength: "30",
                      disabled: _vm.modalData.title !== "기타"
                    },
                    domProps: { value: _vm.modalData.contents },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.modalData, "contents", $event.target.value)
                      }
                    }
                  })
                : _vm._e()
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "msg" }, [
      _c("span", { staticClass: "color-lavender" }, [
        _vm._v("헤이비글 알리미")
      ]),
      _c("br"),
      _c("br"),
      _c("span", { staticStyle: { "font-weight": "500" } }, [
        _vm._v("예약이 완료되었어요!")
      ]),
      _c("br"),
      _c("span", { staticStyle: { color: "#5a5a5a" } }, [
        _vm._v("이제 상세한 내용을"),
        _c("br"),
        _vm._v("협의하세요.")
      ]),
      _c("br"),
      _c("br"),
      _c("span", { staticStyle: { color: "#5a5a5a" } }, [
        _vm._v("전문가와 빠른 소통이 필요하다면?"),
        _c("br"),
        _vm._v("📞로 전문가와 직접 연락하세요.")
      ]),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { color: "#5a5a5a" } }, [
      _vm._v("원하는 전문가를 섭외하셨다면?"),
      _c("br"),
      _vm._v("리뷰 작성하고 상품권 받아가세요.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { color: "#5a5a5a" } }, [
      _vm._v("1. 전화 상담 및 연락처 교환은"),
      _c("br"),
      _vm._v("결제(예약) 후에만 할 수 있어요.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { color: "#5a5a5a" } }, [
      _vm._v("2. 상담이 끝나면 ‘예약하기’로"),
      _c("br"),
      _vm._v("섭외 예약을 완료하세요.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { color: "#5a5a5a" } }, [
      _vm._v("이제 카톡, 통화 등을 통해"),
      _c("br"),
      _vm._v("자유롭게 상담을 진행하세요.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { color: "#5a5a5a" } }, [
      _vm._v("[참고하세요]"),
      _c("br"),
      _vm._v("취소 및 환불 규정")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "msg" }, [
      _c("span", { staticClass: "color-lavender" }, [
        _vm._v("헤이비글 알리미")
      ]),
      _c("br"),
      _c("br"),
      _c("span", { staticStyle: { "font-weight": "500" } }, [
        _vm._v("행사가 끝나면"),
        _c("br"),
        _vm._v("리뷰를 요청하세요!")
      ]),
      _c("br"),
      _c("br"),
      _c("span", { staticStyle: { color: "#5a5a5a" } }, [
        _vm._v("리뷰수가 많은 분들의"),
        _c("br"),
        _vm._v("수익이 절대적으로 많습니다.")
      ]),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { color: "#5a5a5a" } }, [
      _vm._v("전화 상담 및 연락처 교환은"),
      _c("br"),
      _vm._v("결제(섭외) 후에만 할 수 있어요."),
      _c("br"),
      _vm._v("위반 시 이용 정지됩니다.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "msg" }, [
      _c("span", { staticClass: "ui-weight-5" }, [
        _vm._v("진행 순서 알려드릴게요.")
      ]),
      _c("br"),
      _c("br"),
      _c("span", { staticStyle: { color: "#5a5a5a" } }, [
        _vm._v("1. 상담이 끝나면 ‘예약하기’로"),
        _c("br"),
        _vm._v("섭외 확정(결제)을 요청하세요.")
      ]),
      _c("br"),
      _c("br"),
      _c("span", { staticStyle: { color: "#5a5a5a" } }, [
        _vm._v("2. 확정 후 전화 또는 카톡 등으로"),
        _c("br"),
        _vm._v("자유롭게 소통하세요.")
      ]),
      _c("br"),
      _c("br"),
      _c("span", { staticStyle: { color: "#5a5a5a" } }, [
        _vm._v("3. 행사가 종료되면 리뷰를"),
        _c("br"),
        _vm._v("요청하세요.")
      ]),
      _c("br")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }